import React from 'react'
import ReactPlayer from 'react-player';
import ImgCaribe from '../../images/cumbia.png';
import BtnSiguiente from '../../images/botones_siguiente.png';
import BtnAnterior from '../../images/botones_anterior.png';
import { Link } from 'react-router-dom';

function Caribe() {
  return (
    <div className='video_imagen'>
        <div className='imagen'>
            <img src={ImgCaribe} alt='andina'/>
        </div>
        <div className='video'>
            <ReactPlayer 
                url='https://www.youtube.com/watch?v=HN1PHJE1ZFE' 
                controls
            />
            <div className='botones'>
                <Link to="/amazonia"><img src={BtnAnterior} alt='btn'/></Link>
                <Link to="/orinoquia"><img src={BtnSiguiente} alt='btn'/></Link>
            </div>
        </div>

    </div>
  )
}

export default Caribe