import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import Layout from './Layout/Layout';
import Amazonia from './components/bailes_regiones/Amazonia';


import './App.scss';
import Andina from './components/bailes_regiones/Andina';
import Caribe from './components/bailes_regiones/Caribe';
import Orinoquia from './components/bailes_regiones/Orinoquia';
import Pacifico from './components/bailes_regiones/Pacifico';
import Insular from './components/bailes_regiones/Insular';

const App = () => {
    return (
            <Router>
                <Routes>
                    <Route element={<Layout />}>
                        <Route exac path='/' element={<Andina />}/>
                        <Route exac path='/amazonia' element={<Amazonia />}/>
                        <Route exac path='/caribe' element={<Caribe />}/>
                        <Route exac path='/orinoquia' element={<Orinoquia />}/>
                        <Route exac path='/pacifico' element={<Pacifico />}/>
                        <Route exac path='/insular' element={<Insular />}/>
                    </Route>
                </Routes>
            </Router>
    )
}

export default App