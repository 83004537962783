import React from 'react';
import ReactPlayer from 'react-player';
import ImgInsular from '../../images/insular.png';
import BtnAnterior from '../../images/botones_anterior.png';
import { Link } from 'react-router-dom';

function Insular() {
  return (
    <div className='video_imagen'>
        <div className='imagen'>
            <img src={ImgInsular} alt='andina'/>
        </div>
        <div className='video'>
            <ReactPlayer 
                url='https://www.youtube.com/watch?v=EFNSofz4ZqA' 
                controls
            />
            <div className='botones'>
                <Link to="/pacifico"><img src={BtnAnterior} alt='btn'/></Link>
            </div>
        </div>

    </div>
  )
}

export default Insular