import React from 'react';
import ImgAmazonia from '../../images/amazonia.png';
import BtnSiguiente from '../../images/botones_siguiente.png';
import BtnAnterior from '../../images/botones_anterior.png';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

function Amazonia() {
  return (
    <div className='video_imagen'>
        <div className='imagen'>
            <img src={ImgAmazonia} alt='andina'/>
        </div>
        <div className='video'>
            <ReactPlayer 
                url='https://www.youtube.com/watch?v=pLraBy2WZtE' 
                controls
            />
            <div className='botones'>
                <Link to="/"><img src={BtnAnterior} alt='btn'/></Link>
                <Link to="/caribe"><img src={BtnSiguiente} alt='btn'/></Link>
            </div>
        </div>

    </div>
  )
}

export default Amazonia