import React from 'react';
import ReactPlayer from 'react-player';
import ImgAndina from '../../images/andina.png';
import BtnSiguiente from '../../images/botones_siguiente.png';
import { Link} from 'react-router-dom';

const Andina = () => {
  return (
    <div className='video_imagen'>
        <div className='imagen'>
            <img src={ImgAndina} alt='andina'/>
        </div>
        <div className='video'>
            <ReactPlayer 
                url='https://www.youtube.com/watch?v=pGpksJqkIxI' 
                controls
            />
            <div className='botones'>
                <Link to="/amazonia"><img src={BtnSiguiente} alt='btn'/></Link>
            </div>
        </div>

    </div>
  )
}

export default Andina