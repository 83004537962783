import React from 'react';
import ReactPlayer from 'react-player';
import ImgOrinoqui from '../../images/orinoquia.png';
import BtnSiguiente from '../../images/botones_siguiente.png';
import BtnAnterior from '../../images/botones_anterior.png';
import { Link } from 'react-router-dom';

function Orinoquia() {
  return (
    <div className='video_imagen'>
    <div className='imagen'>
        <img src={ImgOrinoqui} alt='andina'/>
    </div>
    <div className='video'>
        <ReactPlayer 
            url='https://www.youtube.com/watch?v=MhuUZEQT0ks' 
            controls
        />
        <div className='botones'>
            <Link to="/caribe"><img src={BtnAnterior} alt='btn'/></Link>
            <Link to="/pacifico"><img src={BtnSiguiente} alt='btn'/></Link>
        </div>
    </div>

</div>
  )
}

export default Orinoquia